import promise from 'es6-promise';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
promise.polyfill();
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
Vue.prototype.$globalParam = false;


Vue.config.productionTip = false
Vue.use(VueRouter);

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')
