import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Zroot',
    component: () => import(/* webpackChunkName: "zroot" */ '../pages/Zroot.vue'),
    children: [
      {
        path: '',
        name: 'Index',
        component: () => import(/* webpackChunkName: "index" */ '../pages/Index.vue'),
      },
      {
        path: 'prosolu',
        name: 'Prosolu',
        component: () => import(/* webpackChunkName: "prosolu" */ '../pages/Prosolu.vue'),
      },
      {
        path: 'prosoluV8',
        name: 'ProsoluV8',
        component: () => import(/* webpackChunkName: "prosoluV8" */ '../pages/ProsoluV8.vue'),
      },
      {
        path: 'prosoluV5',
        name: 'ProsoluV5',
        component: () => import(/* webpackChunkName: "prosoluV5" */ '../pages/ProsoluV5.vue'),
      },
      {
        path: 'prosoluG6',
        name: 'ProsoluG6',
        component: () => import(/* webpackChunkName: "prosoluG6" */ '../pages/ProsoluG6.vue'),
      },
      {
        path: 'succase',
        name: 'Succase',
        component: () => import(/* webpackChunkName: "succase" */ '../pages/Succase.vue'),
      },
      {
        path: 'collpart',
        name: 'Collpart',
        component: () => import(/* webpackChunkName: "collpart" */ '../pages/Collpart.vue'),
      },
      {
        path: 'colleco',
        name: 'Colleco',
        component: () => import(/* webpackChunkName: "colleco" */ '../pages/Colleco.vue'),
      },
      {
        path: 'news',
        name: 'News',
        component: () => import(/* webpackChunkName: "news" */ '../pages/News.vue'),
      },
      {
        path: 'consulser',
        name: 'Consulser',
        component: () => import(/* webpackChunkName: "consulser" */ '../pages/Consulser.vue'),
      },
      {
        path: 'impleser',
        name: 'Impleser',
        component: () => import(/* webpackChunkName: "impleser" */ '../pages/Impleser.vue'),
      },
      {
        path: 'operaser',
        name: 'Operaser',
        component: () => import(/* webpackChunkName: "operaser" */ '../pages/Operaser.vue'),
      },
      {
        path: 'aboutus',
        name: 'Aboutus',
        component: () => import(/* webpackChunkName: "aboutus" */ '../pages/Aboutus.vue'),
      },
      {
        path: 'postus',
        name: 'Postus',
        component: () => import(/* webpackChunkName: "postus" */ '../pages/Postus.vue'),
      },
      {
        path: 'contactus',
        name: 'Contactus',
        component: () => import(/* webpackChunkName: "contactus" */ '../pages/Contactus.vue'),
      },
      {
        path: 'schocooper',
        name: 'Schocooper',
        component: () => import(/* webpackChunkName: "schocooper" */ '../pages/Schocooper.vue'),
      },
    ]
  },
  {
    path: '/success/successcase',
    name: 'successcase',
    component: () => import(/* webpackChunkName: "successcase" */ '../pages/Successcase.vue'),
  },
  {
    path: '/news/newscase',
    name: 'newscase',
    component: () => import(/* webpackChunkName: "newscase" */ '../pages/Newscase.vue'),
  },
  {
    path: '/collpart/collpartcase',
    name: 'collpartcase',
    component: () => import(/* webpackChunkName: "collpartcase" */ '../pages/Collpartcase.vue'),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }; // 滚动到页面顶部
  }
});

router.beforeEach((to, from, next) => {
  if (to.path === '/aboutus') {
    Vue.prototype.$globalParam = true;
  }
  next();
});

export default router;




